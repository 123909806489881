<template>
  <v-container fill-height justify-content-start :class="{'pa-2': $vuetify.breakpoint.xsOnly}">
    <v-layout column>
      <v-layout row wrap justify-start class="ma-0 max-height-100">
        <v-flex xs8 class="max-height-100">
          <v-layout column class="pa-4 max-height-100 overflow-y-auto">
            <h2 class="display-2 raleway grey--text mt-3 mb-4">{{$t('sections.ticket_order')}}</h2>
            <shift-required>
              <v-layout row class="wrap-none">
                <v-container>
                  <v-layout row wrap class="ma-3">
                    <v-flex xs12 class="my-3">
                      <v-flex xs12 class="caption mb-2">
                        {{$t('ticket_order.pricing_group')}}
                      </v-flex>
                      <v-btn-toggle
                        v-model="pricingGroup"
                        mandatory
                        dense
                        color="primary"
                        background-color="transparent"
                        borderless
                        class="flex-row-wrap"
                      >
                        <v-btn v-for="pricingGroup in pricingGroups" :key="pricingGroup.id" :value="pricingGroup.id">
                          {{pricingGroup.name}}
                        </v-btn>
                      </v-btn-toggle>
                    </v-flex>
                    <visitor
                      :index="i"
                      :deletable="visitors.length > 1"
                      v-for="(visitor, i) in visitors"
                      :key="itemKey(visitor)"
                    />
                    <v-btn class="my-1" outlined @click="$store.commit('ticketOrder/addVisitor')" color="primary">
                      <v-icon class="mr-2">mdi-plus</v-icon>{{$t('ticket_order.actions.add_visitor')}}
                    </v-btn>
                  </v-layout>
                </v-container>
                <order-summary
                  class="flex-none"
                  storeModule="ticketOrder"
                  :enableCancelButton="isDirty"
                  :enableContinueButton="canCompleteOrder"
                  :orderLines="ticketOrderLines"
                  @cancel="$store.commit('ticketOrder/clearOrder')"
                  @continue="confirmModal.triggerOpen = new Date()"
                />
              </v-layout>
            </shift-required>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-layout>
    <payment-confirm-modal
      :currency="currency"
      :paymentMethodId="paymentMethodId"
      :total="totalAmount"
      :triggerOpen="confirmModal.triggerOpen"
      @accept="completeOrder()"
      @closedAfterAccept="redirectToBooking()"
      @closed="resetPaymentErrors()"
      :completedAction="!!orderId"
    >
      <template v-slot:errors>
        <v-card-actions v-if="errors.paymentError">
          <v-layout column>
            <div class="body-2 text-center"><v-icon color="error" class="mr-2">mdi-alert-circle-outline</v-icon>{{$t('ticket_order.error.payment')}}</div>
            <v-layout row justify-center class="ma-0">
              <v-btn text color="primary" @click="completeOrder()" class="mt-3">{{$t('actions.retry')}}</v-btn>
            </v-layout>
          </v-layout>
        </v-card-actions>
        <v-card-actions v-if="errors.ticketGenerationError || errors.printingTicketError">
          <v-layout column>
            <div class="body-2 text-center"><v-icon color="warning" class="mr-2">mdi-alert-outline</v-icon>{{$t('ticket_order.error.receipt')}}</div>
            <v-layout row justify-center class="ma-0">
              <v-btn text v-if="errors.ticketGenerationError" color="primary" @click="getAndPrintPurchaseTicket()" class="mt-3">{{$t('actions.retry')}}</v-btn>
              <v-btn text v-if="errors.printingTicketError" color="primary" @click="printOrderTicket()" class="mt-3">{{$t('actions.retry')}}</v-btn>
            </v-layout>
          </v-layout>
        </v-card-actions>
        <v-layout v-if="loading.complete || loading.getTicket || loading.print" align-center justify-center class="my-4 grey--text body">
          <i>{{
            loading.complete
              ? $t('package_order.loading.complete')
              : loading.getTicket
                ? $t('package_order.loading.ticket')
                : $t('package_order.loading.print')
          }}</i><loading-dots />
        </v-layout>
      </template>
    </payment-confirm-modal>
  </v-container>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import searchMixins from '@/mixins/Search'
import permissionMixins from '@/mixins/Permission'
import localizationMixins from '@/mixins/Localization'
import pricingHelpers from '@/mixins/PricingHelpers'
import printerMixins from '@/mixins/PrinterPeripheralActions'
import peripheralMixins from '@/mixins/PeripheralSocket'
import routeNames from '@/router/routes'
import { PassRequests } from '@/services/pass.requests'
const passService = new PassRequests()
export default {
  mixins: [
    searchMixins,
    permissionMixins,
    localizationMixins,
    pricingHelpers,
    printerMixins,
    peripheralMixins
  ],
  components: {
    ShiftRequired: () => import('@/components/view-components/ShiftRequired.vue'),
    Visitor: () => import('@/components/view-components/ticket-order/Visitor.vue'),
    OrderSummary: () => import('@/components/view-components/OrderSummary.vue'),
    PaymentConfirmModal: () => import('@/components/view-components/modals/PaymentConfirm.vue'),
    LoadingDots: () => import('@/components/app-components/LoadingDots.vue')
  },
  data () {
    return {
      visitorKeys: new WeakMap(),
      currentVisitorKey: 0,
      confirmModal: {
        triggerOpen: null,
        triggerClose: null
      },
      bookingId: null,
      orderId: null,
      base64Ticket: null,
      errors: {
        paymentError: false,
        ticketGenerationError: false,
        printingTicketError: false
      },
      loading: {
        complete: false,
        getTicket: false,
        print: false
      }
    }
  },
  computed: {
    ...mapGetters({
      pricingGroups: 'configuration/pricingGroups',
      currencies: 'configuration/currencies',
      cashPaymentMethodId: 'configuration/cashPaymentMethodId'
    }),
    ...mapState({
      visitors: state => state.ticketOrder.visitors,
      pricingGroupId: state => state.ticketOrder.pricingGroupId,
      passes: state => state.passes.list,
      currencyId: state => state.ticketOrder.currencyId,
      totalAmount: state => state.ticketOrder.totalAmount,
      paymentMethodId: state => state.ticketOrder.paymentMethodId
    }),
    pricingGroup: {
      get () {
        return this.pricingGroupId
      },
      set (value) {
        this.$store.commit('ticketOrder/setPricingGroupId', value)
      }
    },
    canCompleteOrder () {
      return !!this.pricingGroupId &&
        !!this.paymentMethodId &&
        this.visitors.every((v) => { return v.name && v.name.trim() && v.lastName && v.lastName.trim() && v.pass })
    },
    isDirty () {
      return this.visitors.some((v) => { return v.name || v.lastName || v.pass })
    },
    ticketOrderLines () {
      var orderLines = []
      this.visitors.forEach(visitor => {
        if (visitor.pass && !orderLines.find(o => o.pass && o.pass.id === visitor.pass)) {
          const pass = this.passes.find((p) => p.id === visitor.pass)
          const price = this.getPrice(pass.pricingGroupsWithPrice, this.pricingGroup)
          const quantity = this.visitors.filter((v) => v.pass === visitor.pass).length
          orderLines.push({
            name: this.localize({ array: pass.name }),
            quantity,
            amount: price ? price.price : null,
            pass
          })
        }
      })

      return orderLines
    },
    currency () {
      return this.currencyId ? this.currencies.find((c) => c.id === this.currencyId) : null
    }
  },
  created () {
    this.$store.dispatch('passes/list')
  },
  methods: {
    itemKey (item) {
      if (!this.visitorKeys.has(item)) {
        this.visitorKeys.set(item, ++this.currentVisitorKey)
      }
      return this.visitorKeys.get(item)
    },
    completeOrder () {
      this.errors.paymentError = false
      this.loading.complete = true
      this.$store.dispatch('ticketOrder/complete').then(({ bookingId, orderId }) => {
        this.bookingId = bookingId
        this.orderId = orderId
        this.getAndPrintPurchaseTicket()
        this.$store.commit('state/setMessage', { text: this.$t('ticket_order.success'), color: 'success' })
      }).catch(() => {
        this.errors.paymentError = true
      }).finally(() => {
        this.loading.complete = false
      })
    },
    getAndPrintPurchaseTicket () {
      this.loading.getTicket = true
      this.errors.ticketGenerationError = false
      passService.getTicket(this.bookingId, this.orderId).then((base64Pdf) => {
        this.base64Ticket = base64Pdf
        this.printOrderTicket()
      }).catch(() => {
        this.errors.ticketGenerationError = true
      }).finally(() => {
        this.loading.getTicket = false
      })
    },
    printOrderTicket () {
      this.loading.print = true
      this.errors.printingTicketError = false
      this.sendPrinterAction(this.base64Ticket, 'application/pdf', () => {
        if (this.paymentMethodId !== this.cashPaymentMethodId) {
          this.redirectToBooking()
        }
        this.loading.print = false
      }, () => {
        this.errors.printingTicketError = true
        this.loading.print = false
      })
    },
    redirectToBooking () {
      if (!this.errors.paymentError && this.bookingId) {
        this.$router.push({ name: routeNames.ticket.name, params: { id: this.bookingId } })
        this.$store.commit('ticketOrder/clearOrder')
        this.bookingId = null
      }
    },
    resetPaymentErrors () {
      this.errors.paymentError = false
      this.errors.ticketGenerationError = false
      this.errors.printingTicketError = false
    }
  },
  beforeDestroy () {
    this.$store.commit('ticketOrder/clearOrder')
  }
}
</script>
